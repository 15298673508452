.article-actions-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 2rem 0;
}

.article-share-btn {
  position: fixed;
  top: 84px;
  right: 104px;
  z-index: 5;
  line-height: 39px !important;
}

.modal-blog .anticon-close {
  font-size: 25px !important;
  line-height: 65px !important;
}
