@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: white;
  overflow: auto;
}

html {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.annet-card-cover-container:hover {
  cursor: pointer;
}

.ant-layout {
  background-color: white !important;
}

.login-form-responsive-padding {
  padding: 3rem 8rem;
}

.double-bloc.left.small-on-med-and-down {
  height: 100%;
}

.blog-paragraph {
  font-size: 16px;
  letter-spacing: -0.005em;
  line-height: 1.8;
  margin-bottom: 23px;
}

.event-icon {
  width: 50px;
  background-color: #004c81;
  padding: 8px;
  border-radius: 61px;
}

@media screen and (max-width: 576px) {
  .ant-list-item-extra {
    margin: 0 !important;
    margin-bottom: 16px !important;
    width: 100% !important;
  }

  .ant-list-item-extra img {
    width: 100% !important;
  }

  .ant-spin {
    left: 39% !important;
  }
}

@media (min-width: 1200px) {
  .blog-paragraph {
    font-size: 18px;
  }
}

@media screen and (max-width: 438px) {
  .ant-list-item-meta-avatar > img,
  .event-icon.item-event {
    width: 77px !important;
  }
}

#home-layout .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#home-layout .trigger:hover {
  color: #1890ff;
}

#home-layout .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

#home-layout .sidenav-content {
  border-right: none;
  flex-grow: 2;
  background-color: white;
}

#home-layout .ant-menu-vertical {
  border-right: none !important;
}

#home-layout .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}

#home-layout .ant-menu-inline {
  border: none !important;
}

#home-layout .sidenav-header {
  background-color: white;
  border-bottom: 1px solid #e8e8e8;
}

#home-layout .no-padding-on-mobile .ant-card-head {
  border: none !important;
}

#home-layout .ant-dropdown-link {
  display: inline-block;
  height: 100%;
  padding: 0 7px;
}

#home-layout .ant-dropdown-link:hover {
  background-color: #e6f7ff;
}

.special-tip .ant-tooltip-content .ant-tooltip-inner {
  background-color: rgb(0, 0, 0);
}

.a-without-style {
  color: rgba(0, 0, 0, 0.65);
}

.a-without-style:hover {
  color: rgba(0, 0, 0, 0.89);
}

/************************
         iarouse
************************/

.blog-card-list-v1 {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: white;
  border-radius: 0.375rem;
  overflow: hidden;
}

.blog-card-list-v1 img {
  width: 100%;
  max-width: 100%;
  position: absolute;
  left: 0;
}

.blog-card-list-v1 .blog-card__img {
  padding: 0;
}

.blog-card-list-v1 .blog-card__body {
  display: flex;
  flex-direction: column;
  padding: 1.625rem;
}

.blog-card-list-v1 .blog-card__tag {
  font-size: 0.75rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 500;
  opacity: 0.8;
}

.blog-card-list-v1 .blog-card__title {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.3125;
  margin: 1rem 0 0.625rem;
}

.article-featured .blog-card-list-v1 .blog-card__title {
  font-size: 2.5rem;
  font-weight: bold;
  text-transform: uppercase;
}

.article-not-featured .blog-card-list-v1 .blog-card__title {
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;
}

.blog-card-list-v1 .blog-card__content {
  padding: 0 0 0.8rem;
  flex: 1;
}

.blog-card-list-v1 .blog-card__author {
  padding-top: 1.5rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 1.625rem;
  font-size: 0.75rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 500;
}

.profile-card-v2 {
  border: none;
  padding: 1rem 0rem;
  border-radius: 0.375rem;
  background-color: white;
  text-align: center;
}

.profile-card-v2 .image {
  margin-bottom: 1.25rem;
  width: 100px;
  border-radius: 50%;
  box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.1), 0 0 10px rgba(255, 255, 255, 0.2);
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  background-position: center;
  background-size: cover;
}

.profile-card-v2 h4 {
  margin: 0;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.625rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile-card-v2 h4,
.profile-card-v2 span {
  display: block;
  opacity: 0.8;
  margin-bottom: 0.875rem;
}

.no-padding-on-mobile .ant-card-head-title {
  text-align: center;
}

.modal-blog .ant-modal-close-x {
  color: #134c81 !important;
}
/*************************
  Media queries from 
  materializecss
*************************/
@media only screen and (max-width: 1241px) {
  .login-form-responsive-padding {
    padding: 3rem 4rem;
  }
}

@media only screen and (max-width: 600px) {
  .login-form-responsive-padding {
    padding: 3rem 1.5rem;
  }
}

@media only screen and (max-width: 767px) {
  .hide-on-small-only,
  .hide-on-small-and-down {
    display: none !important;
  }
}

@media only screen and (max-width: 992px) {
  .hide-on-med-and-down {
    display: none !important;
  }

  .double-bloc.left.small-on-med-and-down {
    height: 126px;
    position: fixed;
    width: 100%;
    z-index: 2;
  }

  .double-bloc.left.small-on-med-and-down > div > div > img {
    max-width: 240px !important;
    margin-top: -14px;
  }
}

@media only screen and (min-width: 768px) {
  .hide-on-med-and-up {
    display: none !important;
  }
}

@media only screen and (min-width: 767px) and (max-width: 992px) {
  .hide-on-med-only {
    display: none !important;
  }
}

@media only screen and (min-width: 993px) {
  .hide-on-large-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1201px) {
  .hide-on-extra-large-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1201px) {
  .show-on-extra-large {
    display: block !important;
  }
}

@media only screen and (min-width: 993px) {
  .show-on-large {
    display: block !important;
  }
}

@media only screen and (min-width: 767px) and (max-width: 992px) {
  .show-on-medium {
    display: block !important;
  }
}

@media only screen and (max-width: 767px) {
  .show-on-small {
    display: block !important;
  }
}

@media only screen and (min-width: 768px) {
  .show-on-medium-and-up {
    display: block !important;
  }
}

@media only screen and (max-width: 992px) {
  .show-on-medium-and-down {
    display: block !important;
  }
}

@media only screen and (max-width: 767px) {
  .center-on-small-only {
    text-align: center;
  }
}

@media only screen and (max-width: 576px) {
  .text-centered-on-mobile {
    text-align: center;
  }
}

@media only screen and (max-width: 606px) {
  .no-padding-on-mobile .ant-card-body {
    padding: 0 !important;
  }
}

.logo-on-left-profile {
  display: none;
}

.menu-image-icon {
  width: 38px;
  height: 38px;
  margin-right: 1em;
}
.modal-investors {
  width: 80% !important;
}

.modal-investors .ant-modal-body {
  height: 100%;
  padding: 44px;
  max-height: 96vh;
  overflow: auto;
}

.deltagereCard:hover {
  box-shadow: 0px 0px 7px #ccc;
  border-radius: 6px;
}

.rdw-editor-main {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 12px;
}

.modal-investors .ant-swipe-modal-body {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.modal-investors .modal-content {
  overflow-y: auto;
}

.ant-btn-primary {
  background-color: #134c81;
  border-color: #134c81;
}

.navigation-buttons Button {
  position: absolute;
  z-index: 55;
}

.navigation-buttons .navigation-buttons-left {
  left: -26px;
  top: 183px;
}

.navigation-buttons .navigation-buttons-right {
  right: -26px;
  top: 183px;
}

.investeringer-sub-menu > a {
  color: rgba(0, 0, 0, 0.65);
  display: block;
}

.investeringer.submenu {
  display: none;
}

.notifications {
  font-size: 1.7em;
  color: #134c81;
}

.header-right sup {
  right: 23px;
  top: 13px;
}

.header-right .ant-badge {
  display: block;
  height: 100%;
  float: left;
  line-height: 57px;
}

.anticon-home.home-class {
  background: #134c81;
  border-radius: 46px;
  height: 46px;
  width: 46px;
  line-height: 46px !important;
  font-size: 22px !important;
  /*display:none;*/
}

.dropdown-menu-link {
  display: block;
  color: #134c81;
}

.dropdown-menu-separator {
  border: 0.7px solid rgba(0, 0, 0, 0.2);
  margin-top: 1em;
}

.rounded-icon-container {
  width: 46px;
  height: 46px;
  background-color: #134c81;
  border-radius: 46px;
}

.list-item-horizontal {
  border-bottom: 1px solid #e8e8e8;
  padding: 12px 10px;
}

.notifications-list {
  min-width: 310px;
}

.notifications-list .ant-dropdown-menu-items {
  padding: 0;
}

.notifications-list .list-item-horizontal:hover {
  cursor: pointer;
  border-left: 6px solid #559cdf;
  padding: 12px 4px;
  background-color: rgba(64, 142, 211, 0.1);
}

#krypto-iframe {
  width: 100%;
  border: none;
  height: 89vh;
}

#krypto-iframe html body section .container > div:first-child {
  display: none;
}

.investment-col {
  padding: 1.5em;
}

.investment-card {
  width: 100%;
}

.investment-card .ant-card-cover {
  height: 224px;
  background-color: rgba(0, 0, 0, 0.035);
  line-height: 198px;
}

.investment-card.dark .ant-card-cover {
  background-color: rgba(255, 255, 255, 0.4);
}

.investment-card .annet-card-cover-container {
  padding: 1.5em;
  text-align: center;
}

.investment-card .annet-card-cover-container img {
  max-width: 85%;
}

.right-dropdown .last-menu {
  border-bottom: 1px solid #ccc !important;
  margin-bottom: 1em;
}

.blog-card__button {
  width: 12em;
  right: 25px;
  bottom: 24px;
  position: absolute;
}

.flex-grow {
  flex-grow: 1;
}

.button-floating {
  width: 50px !important;
  height: 50px !important;
  font-size: 2em !important;
  position: fixed;
  bottom: 90px;
  right: 20px;
  box-shadow: 0px 1px 5px #000 !important;
}

.modal-blog {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 65px 0px 0px 0px !important;
  max-width: none !important;
}

.modal-blog .ant-modal-content {
  height: 100% !important;
  border-radius: 0 !important;
}

.modal-blog .ant-modal-body {
  padding: 50px 0px;
  height: 100%;
  overflow: overlay;
}

.modal-blog img {
  width: 100%;
}

.ant-form-item label {
  font-weight: bold;
}

/*Bootstrap container*/
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.container img {
  max-width: 100%;
  height: auto;
}

.small-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.small-container-pager {
  width: 66.66667%;
  margin: auto;
  font-size: 1.2em;
  padding-bottom: 1.76rem;
}

@media (min-width: 300px) {
  .small-container {
    width: 95%;
  }

  .small-container-pager {
    width: 95%;
  }
}

@media (min-width: 768px) {
  .small-container {
    width: 95%;
  }

  .small-container-pager {
    width: 95%;
  }
}
@media (min-width: 992px) {
  .small-container {
    width: 800px;
  }
}
@media (min-width: 1200px) {
  .small-container {
    width: 900px;
  }
}

@media (max-width: 766px) {
  #legend1 {
    /* text-align: center; */
    padding: 2% 10% 0% 10%;
  }
  #legend1 > ul {
    padding-inline-start: 0;
  }
}

.small-container img {
  width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 600px;
  object-position: center;
  margin-bottom: 1em;
  border-radius: 1em;
}

.blog-entry-card {
  padding-bottom: 50%;
  height: 0;
  position: relative;
  overflow: hidden;
}

.blog-entry-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 2;
}

.overlay-blog-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(19, 76, 129, 0.71);
  z-index: 3;
}

.blog-cart__author {
  color: #fff;
  /* position: absolute;
  bottom: 0; */
  margin-left: 2%;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: Montserrat !important;
}

.blog-card__title {
  color: white;
  text-shadow: 1px 1px 1px #000;
  text-align: left;
  /* text-align: right;
  position: absolute;
  bottom: 20px;
  /* right: 17px; */
  padding-left: 2%;
  width: 90%;
  font-size: 1.7rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-family: Montserrat !important;
}

.article-not-featured .blog-card__title {
  font-size: 18px;
}

.article-featured .blog-entry-card {
  padding-bottom: 40%;
}

.overlay-blog-title {
  position: absolute;
  bottom: 0;
  z-index: 4;
  color: white;
  width: 100%;
  /* height: 100%; */
  margin: auto;
}

.blog-title {
  font-size: 2rem;
}

.blog-title.overlayed {
  color: white;
  font-weight: bolder;
  text-align: center;
  padding: 8% 10%;
  font-size: 3em;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.blog-header {
  width: 100%;
  height: 0;
  padding-bottom: 25%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
}

.modal-blog .ant-modal-close-x {
  color: white;
}

.modal-blog .container {
  padding-top: 27%;
  padding-left: 0px;
  padding-right: 0px;
}

.modal-blog .ant-modal-body {
  position: relative;
}

.bg-hero {
  width: 100%;
  height: 0;
  padding-bottom: 42%;
  position: absolute;
  overflow: hidden;
  top: -24px;
  left: 0;
  transform: scale(1, 1);
}

.bg-hero img {
  width: 100%;
  position: absolute;
  top: -24%;
  z-index: 1;
}

.bg-hero-overlay {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.44);
}

.bg-hero-title {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
  color: white;
  font-size: 3rem;
  text-align: center;
  font-weight: bolder;
  padding-top: 18%;
}

.heist-content {
  margin-top: 42%;
  z-index: 10;
}

.caption {
  margin-bottom: 1.75rem;
  font-size: 0.7em;
}

@media only screen and (max-width: 500px) {
  .blog-entry-card {
    padding-bottom: 54%;
  }

  .blog-title.overlayed {
    font-size: 2em;
    width: 100%;
  }

  .modal-blog .ant-modal-body {
    padding: 50px 1px;
  }

  .small-container-pager {
    font-size: 0.9em;
  }
}

.ant-layout-content {
  padding: 24px 7px 24px 7px !important;
  /* overflow-x: hidden !important;*/
}

@media screen and (min-width: 1204px) {
  .ant-layout-content {
    padding: 24px 8px 24px 8px !important;
  }
  
}

/* Move mobile navbar on bottom */
@media only screen and (max-width: 768px) {
  .blog-card__title {
    font-size: 18px !important;
  }

  .article-featured .blog-entry-card {
    padding-bottom: 54%;
  }

  .ant-menu.ant-menu-light.ant-menu-root.ant-menu-vertical {
    display: flex !important;
  }

  .investment-card .ant-card-cover {
    height: 200px;
    line-height: 148px;
  }

  .iframe-krypto-container {
    padding-top: 83px;
  }

  .header-right .ant-badge {
    float: right;
  }

  #home-layout {
    flex-direction: column-reverse !important;
  }

  .ant-layout-sider {
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
    height: 64px !important;
    flex: 0 0 64px !important;
    border: 0.3px solid #e8e8e8;
    /*border-top: none;*/
    margin-bottom: 1px;
    position: fixed;
    bottom: -1px;
  }

  #home-layout .ant-layout {
    padding-bottom: 32px;
    /* padding-top: 32px; */
  }

  #home-layout .ant-layout-sider-children {
    flex-direction: row !important;
  }

  #home-layout .ant-menu-inline,
  #home-layout .ant-menu-inline-collapsed {
    display: flex;
    justify-content: space-between;
    width: 100% !important;
    height: 100% !important;
  }

  #home-layout .ant-menu-inline .ant-menu-item,
  #home-layout .ant-menu-inline .ant-menu-submenu-title {
    width: 46px !important;
    padding: 0 !important;
    margin: auto;
  }

  #home-layout .ant-menu-inline .ant-menu-item > a,
  #home-layout .ant-menu-inline .ant-menu-submenu-title > a {
    width: 100%;
    margin: auto;
  }

  #home-layout .ant-menu-item {
    height: 100% !important;
    margin-top: 0px !important;
    line-height: 64px !important;
    flex-grow: 1;
    text-align: center;
    margin-bottom: 0px !important;
    padding: 0 !important;
    width: 46px;
  }

  #home-layout .sidenav-content {
    width: 50%;
  }

  #home-layout .ant-layout-header {
    width: 100%;
    position: absolute;
    top: 0;
  }
  .ant-tooltip {
    display: none !important;
  }

  .logo-f-desktop {
    display: none !important;
  }

  .header-right {
    width: 100%;
  }

  .header-right a.ant-dropdown-link {
    float: none;
  }

  .right-buttons-container {
    float: right;
    height: 100%;
  }

  .header-right a.logo-on-left-profile {
    float: left;
  }

  .profile_name {
    display: none;
  }

  .logo-on-left-profile {
    display: block !important;
  }

  .logo-on-left-profile img {
    padding: 0px !important;
    margin-left: 7px !important;
    margin-top: -6px;
  }

  .ant-layout-content {
    /* padding: 24px 24px 55px 24px !important; */
    padding: 24px 7px !important;
  }

  .menu-image-icon {
    width: 45px;
    height: 45px;
    margin-right: 0;
  }

  .modal-investors {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    padding: 64px 0px !important;
  }

  .modal-investors .ant-modal-content {
    height: 100% !important;
    border-radius: 0 !important;
  }

  .navigation-buttons {
    display: none;
  }

  .ant-menu-submenu-title {
    height: 100% !important;
    margin: 0;
    padding: 0 !important;
  }

  .ant-menu-submenu-title .investeringer-sub-menu {
    margin-top: 8px;
  }

  .submenu-investeringer-class {
    flex-grow: 1;
    padding: 0 !important;
    overflow: visible !important;
  }

  .ant-menu-submenu-popup {
    display: none !important;
  }

  .ant-menu > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 0 !important;
    overflow: visible !important;
  }

  .ant-menu
    > .ant-menu-submenu
    > .ant-menu-submenu-title
    > .investeringer-sub-menu {
    overflow: visible !important;
    text-align: center;
  }

  .ant-menu-submenu-active.submenu-investeringer-class {
    background-color: #e6f7ff;
  }

  .ant-menu-inline .ant-menu-submenu-title {
    overflow: visible !important;
  }

  .investeringer-sub-menu > a:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    content: '';
  }

  .investeringer.submenu {
    width: 100%;
    height: 63px;
    display: flex;
    position: absolute;
    transform: translate3d(0, 0, 0);
    top: 63px;
    margin-left: 0;
    flex-direction: row;
    left: 0;
    justify-content: space-evenly;
    z-index: 56;
    background-color: #fff;
    border-bottom: 1px solid rgb(232, 232, 232);
  }

  .investeringer.submenu > a {
    line-height: 63px;
  }

  .ant-menu-submenu-title {
    overflow: visible;
  }

  .investeringer-sub-menu > a {
    display: block;
    width: 100%;
  }

  .investeringer-container {
    /*padding-top: 100px;*/
  }

  .not-found-404-container {
    padding-top: 28px;
  }
}

@media (min-width: 1200px) {
  .ant-col-xl-5 {
    width: 20%;
  }
}
@media (max-width: 766px) {
  .two-or-one-line {
    display: inline-block;
    padding-right: 1em;
  }
}

@media (max-width: 425px) {
  .blog-header {
    padding-bottom: 68%;
  }
  .modal-blog .container {
    padding-top: 68%;
  }
}
@media (max-width: 330px) {
  .blog-header {
    padding-bottom: 72%;
  }

  .modal-blog .container {
    padding-top: 67%;
  }
}

.blog-card-list-v1:hover {
  border: solid #134c81 1px;
}

.polls.polls-title {
  text-align: center;
  font-size: 1.7rem;
}

.polls.polls-choices {
  text-align: center;
}

.pulse {
  position: absolute;
  top: 50px;
  left: 50%;
  margin-left: -36px;
  width: 72px;
  height: 72px;
  line-height: 72px;

  font-family: sans-serif;
  font-weight: 700;
  font-size: 34px;
  text-align: center;

  color: rgba(90, 189, 222, 1);
}

/* .pulse span,
.pulse:before,
.pulse:after */
/* .pulse span, */
.pulse:before,
.pulse::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 0 solid rgba(90, 189, 222, 1);
  border-radius: 100%;
}

/* .pulse span {
  animation: da-pulse 1.5s ease-in-out 0s infinite;
} */

.pulse::before,
.pulse::after {
  animation: da-pulse 1.5s ease-in-out 0s infinite;
}

.pulse span {
  border-color: rgba(26, 76, 129, 1);
  border-width: 4px;
  top: 6px;
  left: 6px;
  right: 6px;
  bottom: 6px;
}

.pulse:before {
  border-color: darken(rgba(26, 76, 129, 1), 25%);
  border-width: 2px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.pulse:after {
  border-color: darken(rgba(26, 76, 129, 1), 35%);
  border-width: 1px;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
}

@keyframes da-pulse {
  0%,
  100% {
    transform: scale(1.25);
    opacity: 0;
  }
  48%,
  52% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes pulse3 {
  from {
    transform: scale(1.5);
  }
  50% {
    transform: scale(0.85);
  }
  to {
    transform: scale(1.5);
  }
}

.fe-pulse {
  animation-name: pulse3;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.investment-col {
  border-radius: 1.5em;
}

.investment-card-reworked {
  line-height: 142px;
  text-align: center;
}

.investment-col:hover {
  box-shadow: 0px 0px 7px #ccc;
  cursor: pointer;
}

.investment-card-reworked > img {
  max-width: 100%;
}

@media screen and (max-width: 579px) {
  .annet-container {
    padding-top: 4rem;
  }
}

.modal-blog .ant-modal-close {
  background-color: white;
  border-radius: 50%;
  right: 30px;
  top: 10px;
  color: black;
  box-shadow: 0px 0px 6px #ccc;
}

.chart-legend
  .ant-table-thead
  > tr
  > th
  .ant-table-header-column
  .ant-table-column-sorters::before {
  content: none;
}

.no-padding-on-mobile .ant-card-head {
  padding: 0px !important;
}

.chart-legend .sorter-container:hover {
  background-color: #cccccc;
  cursor: pointer;
}

.chartlegend-table .legend-title-container {
  display: flex;
}

.chartlegend-table .legend-title-container .legend-square-color {
  height: 15px;
  width: 15px;
  margin-right: 15px;
}

.chartlegend-table .ant-table-column-title {
  font-weight: bold;
}

.chartlegend
  .ant-table-column-sorter-inner
  .ant-table-column-sorter-inner-full {
  font-size: 1rem !important;
}

@media only screen and (max-width: 768px) {
  .ant-layout-content {
    padding: 5px !important;
  }

  .container-with-padding-mobile {
    padding-top: 2rem;
  }
}

@media only screen and (max-width: 768px) {
  .investeringer-container {
    padding-top: 0px !important;
    margin-top: -15px;
  }

  .legend-container {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .chartlegend-table {
    margin-top: 0px !important;
  }
}

.chartlegend-table {
  margin-top: 17px;
}

.investeringer-card-container .ant-card-head-title {
  padding-top: 8px;
}

@media only screen and (min-width: 768px) {
  .investeringer-container .ant-row {
    margin-left: 0px !important;
  }
  
  .investeringer-container .ant-card-body {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  
  .investeringer-container .ant-col {
    padding-left: 0px !important;
  }

  .content-layout .article-featured {
    padding-left: 0px !important;
  }
  
  .content-layout .article-not-featured {
    padding-left: 0px !important;
  }

  .ant-layout-content {
    padding: 5px !important;
    overflow-y: overlay !important;
  }
}

.desktop-view {
  display: inherit;
  width: 100%;
}

.mobile-view {
  display: block;
  width: 100%;
}

.layout-width {
  width: 65%;
  margin: auto;
}

.content-layout {
  margin-top: 66px !important;
}

@media only screen and (min-width: 1520px) {
  .header-content {
    max-width: 1292px;
  }

  .layout-width {
    width: 50%;
  }

  .event-container {
    max-width: 1280px;
    margin-left: -2px;
  }

  .deltagere-row .deltagereCard:nth-child(2) .social-btn,
  .deltagere-row .deltagereCard:nth-child(3) .social-btn {
    justify-content: space-evenly !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1520px) {
  .layout-width {
    width: 80%;
    margin: auto;
  }

  .deltagere-row .deltagereCard:nth-child(2) .social-btn,
  .deltagere-row .deltagereCard:nth-child(3) .social-btn {
    justify-content: space-evenly !important;
  }
}

@media only screen and (min-width: 1111px) and (max-width: 1199px) {
  .layout-width {
    width: 75%;
    margin: auto;
  }

  .social-btn {
    justify-content: space-evenly !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1110px) {
  .layout-width {
    width: 90%;
    margin: auto;
  }

  .social-btn {
    justify-content: space-evenly !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .layout-width {
    width: 100%;
    margin: auto;
  }

  .social-btn {
    justify-content: space-evenly !important;
  }
}

@media only screen and (max-width: 768px) {
  .desktop-view {
    display: none !important;
  }

  .layout-width {
    width: 100%;
    margin: auto;
  }

  .social-btn {
    justify-content: space-evenly !important;
  }

  .modal-blog {
    padding: 64px 0px !important;
  }

  .content-layout {
    padding-bottom: 65px !important;
  }

  .event-container {
    margin-top: -64px !important;
  }

  .modal-blog .ant-modal-body {
    padding-left: 2%;
    padding-right: 2%;
  }
}

@media only screen and (min-width: 769px) {
  .mobile-view {
    display: none !important;
  }
  #home-layout .ant-dropdown-link {
    padding-right: 7px !important;
  }
}

.navbar-menu {
  margin-top: 6px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  /* text-align: center; */
}

.navbar-menu .menu-image-icon {
  width: 40px;
  height: 40px;
  margin-right: 0px !important;
}

.navbar-menu .anticon-home.home-class {
  margin-top: 5px;
  height: 40px;
  width: 40px;
}

.navbar-menu .anticon {
  margin-right: 0px !important;
} 

.navbar-menu .ant-menu-item {
  margin-left: 2%;
  margin-right: 2%;
}

.navbar-menu .ant-menu-item a {
  padding-bottom: 10px;
}

.hest-card img {
  width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 250px;
  object-position: center;
  margin-bottom: 1em;
}

.hest-card canvas {
  width: 100%;
  /* height: auto; */
  object-fit: cover;
  height: 250px;
  object-position: center;
  margin-bottom: 1em;
}

.hest-card.grayscale h2 {
  color: rgb(161 160 160);
}

.hest-card.grayscale .ant-collapse-content-box {
  color: rgb(161 160 160);
}

.hest-card .ant-card-body {
  padding: 5px;
}

.hest-card .slick-next:before, .slick-prev:before {
  font-family: slick;
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(19, 76, 129);
  padding-bottom: 5px;
}

.hest-card .ant-carousel .slick-next {
  right: 0px;
}

.hest-card .ant-carousel .slick-prev {
  left: 0px;
  z-index: 1;
}

.om .ant-form-item-label label {
  font-weight: lighter;
}

.om .ant-form-item-label {
  text-align: left;
}

.om .ant-form-item-children {
  font-weight: lighter;
}

.social-btn {
  justify-content: space-evenly;
}

.spin-pulse {
  margin: auto;
  display: block;
  top: 43%;
  left: 48%;
  transform: translateX(50%) translateY(-50%);
  position: absolute;
}

@media only screen and (min-width: 992px) {
  .social-btn {
    justify-content: space-between;
  }

  .pie-container {
    width: 570px;
    height: 570px;
  }

  .legend-container .ant-row {
    margin-top: 0px !important;
  }
}

@media only screen and (max-width: 899px) {
  .spin-pulse {
    left: 45%;
  }

  .modal-setting .top-btn-setting {
    justify-content: left !important;
  }
  
  .modal-setting .top-btn-setting > a.dropdown-menu-link {
    margin: 13px !important;
  }

  .details-form {
    padding-left: 15px;
  }
  
  .chart-filter-wrapper {
    display: block !important;
  }
  
  .chart-filter-wrapper .ant-calendar-picker{
    width: 100% !important;
  }

  .chart-filter-wrapper .ant-radio-group.ant-radio-group-solid {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 2%;
  }
  .btn-truncate {
    /* width: 232px; */
    width: 100%;
    overflow: hidden;
  }

  .tag-wrapper-inline {
    display: block !important;
  }

  .home-icon .anticon.anticon-pic-right {
    font-size: 28px !important;
  }
}

.btn-truncate {
  width: 100%;
  max-width: 232px;
  overflow: hidden;
}

.ant-modal-mask {
  z-index: -1 !important;
}

#home-layout .ant-layout-header {
  z-index: 1200;
}

.ant-layout-sider {
  z-index: 1200;
}

.modal-blog .sektor-select .anticon-close {
  font-size: 14px !important;
  line-height: 10px !important;
}

.sektor-select .ant-select-selection__choice {
  background-color: #40a9ff3b;
}

.modal-setting .ant-modal-body {
  padding: 0px !important;
}

.modal-setting .top-btn-setting {
  display: flex;
  justify-content: right;
  align-items: center;
}

.chart-filter .ant-calendar-time-picker-btn {
  display: none !important;
}

.chart-filter-wrapper {
  display: flex;
  justify-content: center;
}

.chart-filter-wrapper .chart-filter-btn {
  width: 100%;
}

.tag-title {
  display: flex;
}

.tag-title h2 {
  margin-bottom: 0px;
}

.tag-link {
  font-size: 17px;
  transform: rotate(265deg);
  margin-left: 5%;
}

.tag-title.tag-deltagere .tag-link{
  margin-top: 4%;
  font-size: 32px !important;
  margin-right: 8%;
}

.css-1gqi8bq-control {
  border-color: #004c81 !important;
  box-shadow: 0 0 0 1px #004c81 !important;
}

.tag-wrapper-inline {
  display: flex;
}

.home-icon .anticon.anticon-pic-right {
  font-size: 25px;
  padding-top: 8px;
}