@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

#timeline {
  position: relative;
  display: table; //*For Firefox
  height: 100%;
  margin: {
    // left: auto;
    left: 0px;
    right: 0px;
    top: 0.5rem;
  }
  div#timeline-div {
    &:after {
      content: '';
      width: 2px;
      position: absolute;
      top: 0.5rem;
      bottom: 0rem;
      left: 60px;
      z-index: 1;
      background: #c5c5c5;
    }
  }
  h3 {
    position: -webkit-sticky;
    position: sticky;
    top: 4rem;
    color: #888;
    margin: 0;
    font: {
      size: 1em;
      weight: 400;
    }
    @media (min-width: 62em) {
      font-size: 1.1em;
    }
    /* @media (max-width: 57em) {
      top: 4rem;
    } */
  }
  section.year {
    position: relative;
    &:first-child section {
      margin-top: -1.3em;
      padding-bottom: 0px;
    }
    section {
      position: relative;
      padding-bottom: 1.25em;
      margin-bottom: 2.2em;
      h4 {
        position: absolute;
        bottom: 0;
        font: {
          size: 0.9em;
          weight: 400;
        }
        line-height: 1.2em;
        margin: 0;
        padding: 0 0 0 89px;
        color: #c5c5c5;
        @media (min-width: 62em) {
          font-size: 1em;
        }
      }
      ul {
        list-style-type: none;
        padding: 0 0 0 75px;
        margin: -1.35rem 0 1em;
        max-width: 80rem;
        font-size: 1em;
        @media (min-width: 62em) {
          font-size: 1.1em;
          padding: 0 0 0 81px;
        }
        &:last-child {
          margin: {
            bottom: 0;
          }
        }
        &:first-of-type:after {
          content: '';
          width: 14px;
          height: 14px;
          background: #c5c5c5;
          border: 2px solid #ffffff;
          @include border-radius(50%);
          position: absolute;
          left: 54px;
          top: 3px;
          z-index: 2;
        }
        li {
          margin-left: 0.5rem;
          &:not(:first-child) {
            margin-top: 0.5rem;
          }
          span.price {
            color: mediumturquoise;
            font-weight: 500;
          }
        }
      }
    }
  }
}

.flex-event-container {
  display: flex;
}

.flex-event-image {
  margin-left: 40px;
}

@media screen and (max-width: 576px) {
  .flex-event-container {
    flex-wrap: wrap-reverse;
  }

  .flex-event-image {
    margin-left: 0;
    margin-bottom: 16px;
    width: 100%;
  }

  .flex-event-image img {
    width: 100% !important;
  }
}

@media only screen and (max-width: 768px) {
  .ant-layout .ant-layout-content #timeline {
    top: 68px !important;
  }
}

.flex-event-image .banner {
  position: relative;
}

.flex-event-image .btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 376px) and (max-width: 576px) {
  .flex-event-image .btn {
    left: 43% !important;
  }
}

@media screen and (max-width: 375px) {
  .flex-event-image .btn {
    left: 50% !important;
  }
}

.ribbon-container {
  position: relative;
  display: block;
}

.ribbon-container .ribbon-wrapper {
  position: absolute;
  overflow: hidden;
  width: 100px;
  height: 88px;
  right: 0px;
}

.ribbon-container .ribbon {
  position: relative;
    display: block;
    text-align: center;
    font-size: 15px;
    color: #fff;
    transform: rotate(45deg);
    padding: 7px 0;
    left: -5px;
    top: 17px;
    width: 142px;
    line-height: 20px;
    background-color: #495057;
}
