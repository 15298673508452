.home-icon {
  background-color: #004b82;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  line-height: 40px;
  color: white;
  text-align: center;
  margin: auto;
  margin-top: 5px;
}

.home-icon .anticon-home {
  font-size: 22px !important;
  line-height: 45px !important;
}

@media only screen and (max-width: 768px) {
  .home-icon {
    width: 46px;
    height: 46px;
    border-radius: 46px;
    line-height: 46px;
    margin-top: 10px;
  }

  .home-icon .anticon-home {
    font-size: 26px !important;
    line-height: 51px !important;
  }
}
